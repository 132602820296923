<template>
  <section class="section memorial-profile-search">
    <div class="title">
      <img :src="magnifierIcon" alt="Magnifier icon" width="24" height="24">

      <span>{{ sectionTexts.title }}</span>
    </div>

    <p class="description">{{ sectionTexts.description }}</p>

    <div class="profile-search-wrapper">
      <div v-if="showProfileUnavailableTooltip" class="tooltip">
        {{ sectionTexts.profileNotFoundErrorMessage }}
      </div>

      <v-form class="finder-container" @submit.prevent="seeMemProfile">
        <v-text-field
          v-model="slotNumber"
          :rules="slotNumberRules"
          autofocus
          class="profile-number-input-field"
          dense
          maxlength="8"
          :placeholder="sectionTexts.searchInputPlaceholder"
          required
        >
        </v-text-field>

        <button
          :disabled="!slotNumber || slotNumber.replace(/-/g, '').length !== 6"
          class="see-profile-button"
          type="submit"
        >
          {{ sectionTexts.seeProfileButtonLabel }}

          <span class="icon-right"></span>
        </button>
      </v-form>
    </div>
  </section>
</template>

<script>
import magnifierIcon from '@/assets/images/magnifier-white-24x24.svg';
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import axiosInstance from '@/interceptors/axios.config';

export default {
  name: 'MemorialProfileSearch',
  
  setup() {
    const router = useRouter();
    const slotNumber = ref('');
    const showProfileUnavailableTooltip = ref(false);
    
    const slotNumberRules = [
      (v) => v.replace(/-/g, '').length === 6 || 'Numer profilu jest wymagany',
      (v) => /^\d{2}-\d{2}-\d{2}$/.test(v) || 'Nieprawidłowy numer profilu.',
    ];

    // Watch for slotNumber changes to format it
    watch(slotNumber, (newValue) => {
      // Remove any non-digit characters
      const digits = newValue.replace(/\D/g, '');
      // Format the digits with hyphens
      const formatted = digits.slice(0, 6).replace(/^(\d{2})(\d{2})?(.*)/, (match, p1, p2, p3) => {
        let result = p1;
        if (p2) result += '-' + p2;
        if (p3) result += '-' + p3;
        return result;
      });
      // Update the value only if it has changed
      if (formatted !== newValue) {
        slotNumber.value = formatted;
      }
    });

    const sectionTexts = {
      title: 'Wyszukaj profil',
      description: 'Kod znajdziesz na dole medalionu.',
      searchInputPlaceholder: 'Wpisz numer profilu',
      seeProfileButtonLabel: 'Zobacz profil',
      profileNotFoundErrorMessage: 'Profil o podanym numerze nie istnieje',
    };

    const checkProfileExists = async (profileNumber) => {
      try {
        const response = await axiosInstance.get(`${process.env.VUE_APP_API_URL}mem-profile/${profileNumber}`);
        return response.status === 200;
      } catch (error) {
        if (error.response && error.response.status === 404) {
          return false;
        }

        throw error;
      }
    };

    const seeMemProfile = async () => {
      try {
        if (slotNumber.value === '78-98-43') {
          slotNumber.value = '55-02-03';
        }

        if (await checkProfileExists(slotNumber.value)) {
          router.push(`/profil/${slotNumber.value}`);
        } else {
          showProfileUnavailableTooltip.value = true;
          setTimeout(() => showProfileUnavailableTooltip.value = false, 3000);
        }
      } catch (error) {
        console.error("Error checking profile:", error);
      }
    };

    return {
      magnifierIcon,
      slotNumber,
      slotNumberRules,
      showProfileUnavailableTooltip,
      sectionTexts,
      seeMemProfile,
    };
  },
};
</script>

<style scoped lang="scss">
.memorial-profile-search {
  min-height: 432px;
  width: calc(100vw - 32px) !important;
  background-color: #135431;
  border-radius: 16px;
  padding: 24px;
  margin: 32px 16px;
  background-image: url('../../../assets/images/medalion-bottom-part-small.png');
  background-position: left 24px top;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media (min-width: 768px) {
    background-image: url('../../../assets/images/medalion-bottom-part.png');
    height: 228px;
    min-height: unset;
    width: 100% !important;
    padding: 48px;
    background-position: right 40px top;
    background-repeat: no-repeat;
    margin: 32px 0;
  }

  .title {
    display: flex;
    align-items: center;
    gap: 4px;
    font-family: 'Source Serif 4', serif;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2rem;
    color: #FFFFFF;
    margin-bottom: 8px;
  }

  .description {
    font-family: 'Source Serif 4', serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #FFFFFF;

    .link {
      color: #FFFFFF;
      font-weight: 600;
    }
  }

  .profile-search-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    margin-top: 24px;

    @media (min-width: 768px) {
      flex-direction: row;
    }
  }

  .tooltip {
    position: absolute;
    bottom: calc(100% + 4px);
    left: auto;
    right: auto;
    background-color: #ffcc00;
    color: #000000;
    padding: 8px 12px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    font-size: 0.875rem;
    transition: opacity 0.3s ease;
    white-space: nowrap;
    pointer-events: none;
    z-index: 1000; /* Ensure the tooltip appears above other elements */

    @media (min-width: 768px) {
      left: 16px;
    }
  }

  .finder-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    gap: 12px;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }

  .profile-number-input-field {
    border-radius: 4px;
    background-color: transparent;
    font-size: 1.25rem;
    font-family: Roboto, sans-serif;

    .v-field__field .v-field__input {
      text-align: center;

      &::placeholder {
        font-size: 1rem;
        letter-spacing: 0.5px;
        line-height: 1.5rem;
        font-weight: 400;
        color: #171717 !important;
      }
    }
  }

  .see-profile-button {
    height: 52px;
    width: 240px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    color: #FFFFFF;
    background-color: #0F4320;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    font-family: Roboto, sans-serif;
    border-radius: 8px;

    @media (min-width: 768px) {
      width: auto;
      min-width: unset;
      height: 56px;
      padding: 16px 14px;
    }

    .icon-right {
      display: inline-block;
      width: 16px;
      height: 16px;
      mask: url('../../../assets/images/arrow-right-white-16x16.svg') no-repeat center / contain;
      background-color: #FFFFFF;
    }
  }
}
</style>
