<template>
  <section class="faq">
    <div class="section-content">
      <div class="section-header">
        <h2 class="section-title">{{ sectionTexts.title }}</h2>
      </div>

      <div class="paragraphs">
        <div
          v-for="(paragraph, index) in sectionTexts.paragraphs"
          :key="index"
          class="paragraph"
          :class="{ 'expanded': expandedParagraphs[index] }"
          @click="toggleParagraph(index)"
        >
          <div class="question-wrapper">
            <h4 class="question">{{ paragraph.title }}</h4>

            <button class="expand-button" :aria-label="expandedParagraphs[index] ? sectionTexts.collapseButtonText : sectionTexts.expandButtonText">
              <span class="expand-toggle-label">{{ expandedParagraphs[index] ? sectionTexts.collapseButtonText : sectionTexts.expandButtonText }}</span>

              <v-icon>
                <img 
                  alt="" 
                  src="../../../assets/images/chevron-down.svg" 
                  width="16" 
                  height="16"
                  :class="{ 'rotated': expandedParagraphs[index] }"
                >
              </v-icon>
            </button>
          </div>

          <transition 
            name="expand" 
            @enter="startTransition" 
            @after-enter="endTransition"
            @before-leave="startTransition" 
            @after-leave="endTransition"
          >
            <div v-show="expandedParagraphs[index]" class="content-wrapper">
              <p class="answer">
                <span>{{ paragraph.description }}</span>

                <span v-if="index === 0">
                  <a
                    class="get-profile-button"
                    target="_blank"
                    :href="sectionTexts.buyProfileUrl"
                    @click.stop="handleBuyClick"
                  >
                    <span>{{ sectionTexts.buyButtonText }}</span>

                    <v-icon>
                      <img alt="" src="../../../assets/images/external-link-icon-white-16x16.svg" width="16" height="16">
                    </v-icon>
                  </a>
                </span>
              </p>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'WhatServiceContains',
  setup() {
    const sectionTexts = {
      title: 'Najczęściej zadawane pytania',
      paragraphs: [
        {
          title: 'Jak mogę nabyć produkt?',
          description: 'Aktualnie produkt dostępny jest u naszych partnerów oraz w naszym sklepie.',
        },
        {
          title: 'Czy profil jest prywatny?',
          description: '...',
        },
        {
          title: 'Czy istnieje możliwośc kilku Profili Pamięci na jednym medalionie QR? ',
          description: '...',
        },
      ],
      buyButtonText: 'Kup Profil Pamięci',
      buyProfileUrl: 'https://cart.easy.tools/checkout/memorial-profile/takzyli-profil-pamieci?ref=takzyli.pl&lang=pl',
      expandButtonText: 'Rozwiń',
      collapseButtonText: 'Zwiń',
    };

    const expandedParagraphs = ref(Array(sectionTexts.paragraphs.length).fill(false));

    const toggleParagraph = (index) => {
      expandedParagraphs.value[index] = !expandedParagraphs.value[index];
    };

    const handleBuyClick = () => {
      console.log('Buy profile clicked');
    };

    const startTransition = (element) => {
      const height = element.scrollHeight;
      element.style.height = '0';
      // Force repaint
      element.offsetHeight;
      element.style.height = `${height}px`;
    };

    const endTransition = (element) => {
      element.style.height = 'auto';
    };

    return {
      sectionTexts,
      expandedParagraphs,
      toggleParagraph,
      handleBuyClick,
      startTransition,
      endTransition,
    };
  },
};
</script>

<style scoped lang="scss">
.faq {
  width: 100%;
  background-color: #F5F5F5;
  display: flex;
  justify-content: center;

  .section-content {
    display: flex;
    flex-direction: column;
    margin: 24px 40px;

    @media (min-width: 768px) {
      display: block;
      margin: 0 108px 108px;
      padding: 120px 0;
      width: 100%;
      max-width: 960px;
    }
  }

  .section-header {
    display: flex;
    flex-direction: column;
    gap: 4px;

    @media (min-width: 768px) {
      gap: 12px;
      margin-bottom: 48px;
      align-items: center;
    }

    .section-title {
      font-family: 'Source Serif 4', serif;
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 2rem;
      padding-bottom: 16px;

      @media (min-width: 768px) {
        font-size: 2.5rem;
        line-height: 3.5rem;
        max-width: 879px;
        padding-bottom: 0;
      }
    }
  }

  .paragraphs {
    display: flex;
    flex-direction: column;
  }

  .paragraph {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    margin-bottom: 16px;
    text-align: left;
    padding: 16px;
    border-radius: 8px;
    background-color: #FFFFFF;
    cursor: pointer;
    transition: all 0.3s ease;

    @media (min-width: 768px) {
      gap: 18px;
      padding: 24px;
    }

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    }

    .question-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    .question {
      font-family: 'Source Serif 4', serif;
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.5rem;
      color: #135431;
      margin: 0;

      @media (min-width: 768px) {
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }

    .expand-button {
      background-color: transparent;
      border: none;
      border-radius: 8px;
      padding: 0;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 4px;
      font-size: 0.875rem;
      line-height: 1.25rem;
      transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      width: auto;
      white-space: nowrap;
      color: #135431;

      @media (min-width: 768px) {
        min-width: 80px;
        background-color: rgba(#0A0A0A, 0.1);
        padding: 14px;

        &:hover {
          background-color: rgba(#0A0A0A, 0.08);
        }
      }

      img {
        transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        flex-shrink: 0;

        &.rotated {
          transform: rotate(180deg);
        }
      }
    }

    .expand-toggle-label {
      display: none;

      @media (min-width: 768px) {
        display: block;
      }
    }

    .content-wrapper {
      width: 100%;
      overflow: hidden;
      transition: height 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      display: flex;
      flex-direction: column;
      border-radius: 0;
    }

    .answer {
      font-family: 'Source Serif 4', serif;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5rem;
      margin: 0;
      opacity: 1;
      transition: opacity 0.2s ease-in-out;

      @media (min-width: 768px) {
        font-size: 1.25rem;
        line-height: 2rem;
      }
    }
  }

  .get-profile-button {
    padding: 14px 16px;
    font-size: 0.875rem;
    line-height: 1.25rem;
    background-color: #2A4F3C;
    color: #FFFFFF;
    border-radius: 8px;
    text-decoration: none;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    margin-top: 16px;
    width: fit-content;

    &:hover {
      background-color: darken(#2A4F3C, 5%);
    }
  }
}

.expand-enter-active,
.expand-leave-active {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  overflow: hidden;
}

.expand-enter-from,
.expand-leave-to {
  height: 0 !important;
  opacity: 0;
}

.expand-enter-to,
.expand-leave-from {
  height: auto;
  opacity: 1;
}
</style>
