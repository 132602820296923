<template>
  <section class="section how-it-works">
    <div class="section-header">
      <h3 class="section-title ">{{ sectionTexts.title }}</h3>

      <h2 class="section-description">{{ sectionTexts.description }}</h2>
    </div>

    <div class="section-content">
      <div class="paragraphs">
        <div 
          class="paragraph" 
          :class="{ 'is-highlighted': currentVideoIndex === 0 }"
          @click="playVideo(0)"
        >
          <h4 class="paragraph-title">
            <span class="paragraph-icon step-1"></span>

            <span>{{ sectionTexts.paragraph1.title }}</span>
          </h4>

          <p class="paragraph-description">{{ sectionTexts.paragraph1.description }}</p>

          <a
            class="get-profile-button"
            target="_blank"
            :href="sectionTexts.paragraph1.link.url"
            @click.stop="handleBuyClick"
          >
            <span>{{ sectionTexts.paragraph1.link.label }}</span>

            <v-icon>
              <img alt="" src="../../../assets/images/external-link-icon-white-16x16.svg" width="16" height="16">
            </v-icon>
          </a>
        </div>

        <div 
          class="paragraph"
          :class="{ 'is-highlighted': currentVideoIndex === 1 }"
          @click="playVideo(1)"
        >
          <h4 class="paragraph-title">
            <span class="paragraph-icon step-2"></span>

            <span>{{ sectionTexts.paragraph2.title }}</span>
          </h4>
        </div>

        <div 
          class="paragraph"
          :class="{ 'is-highlighted': currentVideoIndex === 2 }"
          @click="playVideo(2)"
        >
          <h4 class="paragraph-title">
            <span class="paragraph-icon step-3"></span>

            <span>{{ sectionTexts.paragraph3.title }}</span>
          </h4>
        </div>

        <div 
          class="paragraph"
          :class="{ 'is-highlighted': currentVideoIndex === 3 }"
          @click="playVideo(3)"
        >
          <h4 class="paragraph-title">
            <span class="paragraph-icon step-4"></span>

            <span>{{ sectionTexts.paragraph4.title }}</span>
          </h4>
        </div>
      </div>

      <div class="video-wrapper">
        <video 
          ref="videoPlayer"
          class="video-player"
          @ended="handleVideoEnd"
          @timeupdate="updateProgress"
          @loadedmetadata="initializeVideo"
        >
          <source :src="currentVideoSrc" type="video/mp4">
        </video>
        <div class="video-controls" :class="{ 'is-playing': isPlaying }">
          <button 
            class="control-button"
            @click="toggleVideo"
          >
            <svg 
              v-show="!isPlaying" 
              class="control-icon play-icon" 
              viewBox="0 0 24 24" 
              fill="none" 
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8 5v14l11-7z" fill="currentColor"/>
            </svg>
            <svg 
              v-show="isPlaying" 
              class="control-icon pause-icon" 
              viewBox="0 0 24 24" 
              fill="none" 
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z" fill="currentColor"/>
            </svg>
          </button>
        </div>
        <div class="time-control">
          <div class="progress-bar" @click="seek">
            <div class="progress" :style="{ width: `${progress}%` }"></div>
          </div>
          <div class="time-display">{{ currentTime }} / {{ duration }}</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {onMounted, ref} from 'vue';

export default {
  name: 'HowItWorks',
  setup() {
    const sectionTexts = {
      title: 'Jak to działa?',
      description: 'Tworzenie Profilu Pamięci krok po kroku',
      paragraph1: {
        title: 'Kup Profil na naszej stronie',
        description: 'W czasie 48h od zakupu wyślemy do Ciebie przesyłkę z medalionem. W mailu otrzymasz informacje odnośnie logowania.',
        link: {
          label: 'Zamów teraz',
          url: 'https://cart.easy.tools/checkout/memorial-profile/takzyli-profil-pamieci?ref=takzyli.pl&lang=pl',
        },
      },
      paragraph2: {
        title: 'Zaloguj się i stwórz Profil Pamięci',
      },
      paragraph3: {
        title: 'Umieść medalion na nagrobku',
      },
      paragraph4: {
        title: 'Zeskanuj kod',
      },
    };

    const videoPlayer = ref(null);
    const currentVideoIndex = ref(0);
    const isPlaying = ref(false);
    const videos = [
      require('../../../assets/videos/1.mp4'),
      require('../../../assets/videos/2.mp4'),
      require('../../../assets/videos/3.mp4'),
      require('../../../assets/videos/4.mp4'),
    ];

    const currentVideoSrc = ref(videos[0]);

    const progress = ref(0);
    const currentTime = ref('0:00');
    const duration = ref('0:00');

    const formatTime = (timeInSeconds) => {
      const minutes = Math.floor(timeInSeconds / 60);
      const seconds = Math.floor(timeInSeconds % 60);
      return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };

    const updateProgress = () => {
      if (videoPlayer.value) {
        progress.value = (videoPlayer.value.currentTime / videoPlayer.value.duration) * 100;
        currentTime.value = formatTime(videoPlayer.value.currentTime);
      }
    };

    const initializeVideo = () => {
      if (videoPlayer.value) {
        duration.value = formatTime(videoPlayer.value.duration);
      }
    };

    const seek = (event) => {
      if (videoPlayer.value) {
        const progressBar = event.currentTarget;
        const clickPosition = event.offsetX;
        const progressBarWidth = progressBar.offsetWidth;
        videoPlayer.value.currentTime = (clickPosition / progressBarWidth) * videoPlayer.value.duration;
      }
    };

    const playVideo = (index) => {
      currentVideoIndex.value = index;
      currentVideoSrc.value = videos[index];
      if (videoPlayer.value) {
        videoPlayer.value.load();
        videoPlayer.value.play();
        isPlaying.value = true;
      }
    };

    const toggleVideo = () => {
      if (videoPlayer.value) {
        if (videoPlayer.value.paused) {
          videoPlayer.value.play();
          isPlaying.value = true;
        } else {
          videoPlayer.value.pause();
          isPlaying.value = false;
        }
      }
    };

    const handleVideoEnd = () => {
      const nextIndex = (currentVideoIndex.value + 1) % videos.length;
      playVideo(nextIndex);
    };

    const handleBuyClick = () => {
      console.log('Buy profile clicked');
    };

    onMounted(() => {
      if (videoPlayer.value) {
        videoPlayer.value.load();
      }
    });

    return {
      sectionTexts,
      handleBuyClick,
      videoPlayer,
      currentVideoIndex,
      currentVideoSrc,
      playVideo,
      handleVideoEnd,
      toggleVideo,
      isPlaying,
      progress,
      currentTime,
      duration,
      updateProgress,
      initializeVideo,
      seek,
    };
  },
};
</script>

<style scoped lang="scss">
.how-it-works {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    display: block;
    padding: 108px;
  }

  .section-header {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 0 40px;

    @media (min-width: 768px) {
      gap: 12px;
      max-width: 720px;
      padding: 0;
    }

    .section-title {
      font-family: 'Source Serif 4', sans-serif;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5rem;

      @media (min-width: 768px) {
        font-size: 1.25rem;
        line-height: 2rem;
      }
    }

    .section-description {
      font-family: 'Source Serif 4', sans-serif;
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 2rem;
      padding-bottom: 16px;

      @media (min-width: 768px) {
        font-size: 2.5rem;
        line-height: 3.5rem;
        max-width: 879px;
        padding-bottom: 0;
      }
    }
  }

  .section-content {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 24px;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: flex-start;
      gap: 18px;
      margin-top: 64px;
    }
  }

  .paragraphs {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 8px;
    padding: 0 40px;
    cursor: pointer;

    @media (min-width: 768px) {
      padding: 0;
    }
  }

  .paragraph {
    font-family: 'Source Serif 4', serif;
    font-size: 0.875rem;
    line-height: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border: 1px solid #E5E5E5;
    border-radius: 8px;
    padding: 24px;
    gap: 0;

    @media (min-width: 768px) {
      height: 80px;
      font-size: 1.25rem;
      line-height: 2rem;
    }

    &:first-child {
      min-height: 220px;
    }

    &.is-highlighted {
      background-color: #F5F5F5;
    }

    .paragraph-icon {
      min-width: 24px;
      min-height: 24px;
      background-position: center;
      background-repeat: no-repeat;

      @media (min-width: 768px) {
        width: 28px;
        height: 28px;
      }

      &.step-1 {
        background-image: url('../../../assets/images/step-1-icon-grey-28x28.svg');
        background-size: 100%;
      }

      &.step-2 {
        background-image: url('../../../assets/images/step-2-icon-grey-28x28.svg');
        background-size: 100%;
      }

      &.step-3 {
        background-image: url('../../../assets/images/step-3-icon-grey-28x28.svg');
        background-size: 100%;
      }

      &.step-4 {
        background-image: url('../../../assets/images/step-4-icon-grey-28x28.svg');
        background-size: 100%;
      }
    }

    .paragraph-title {
      display: flex;
      align-items: center;
      gap: 12px;
      font-family: 'Source Serif 4', serif;
      font-size: 0.875rem;
      line-height: 1.5rem;
      font-weight: 600;
      text-align: start;

      @media (min-width: 768px) {
        font-size: 1.25rem;
      }
    }

    .paragraph-description {
      font-family: 'Source Serif 4', serif;
      font-size: 0.875rem;
      line-height: 1.5rem;
      font-weight: 400;
      text-align: start;
      margin-top: 16px;

      @media (min-width: 768px) {
        font-size: 1rem;
      }
    }
  }

  .get-profile-button {
    height: 48px;
    margin-top: 16px;
    padding: 14px 16px 14px;
    font-size: 0.875rem;
    line-height: 1.25rem;
    background-color: #2A4F3C;
    color: #FFFFFF;
    border-radius: 8px;
    text-decoration: none;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    align-self: center;

    @media (min-width: 768px) {
      align-self: flex-start;
    }
  }

  .video-wrapper {
    width: calc(100% - 64px);
    aspect-ratio: 1 / 1;
    background: rgba(#0A0A0A, 0.1);
    border-radius: 16px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @media (min-width: 768px) {
      min-width: 480px;
      height: 480px;
    }

    .video-player {
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
    }

    .video-controls {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
      z-index: 1;

      &:not(.is-playing) {
        opacity: 1;
      }

      .control-button {
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .control-icon {
        width: 48px;
        height: 48px;
        color: white;
        filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.3));
        opacity: 0.9;
        transition: transform 0.2s ease, opacity 0.2s ease;

        &:hover {
          transform: scale(1.1);
          opacity: 1;
        }
      }
    }

    .time-control {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 32px 24px 16px;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.4) 50%, transparent 100%);
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
      z-index: 2;

      .progress-bar {
        width: 100%;
        height: 3px;
        background: rgba(255, 255, 255, 0.3);
        border-radius: 1.5px;
        cursor: pointer;
        position: relative;
        transition: height 0.2s ease;

        .progress {
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          background: #FFFFFF;
          border-radius: 1.5px;
          transition: width 0.1s linear;

          &::after {
            content: '';
            position: absolute;
            right: -4px;
            top: 50%;
            transform: translateY(-50%);
            width: 8px;
            height: 8px;
            background: #FFFFFF;
            border-radius: 50%;
            opacity: 0;
            transition: opacity 0.2s ease;
          }
        }

        &:hover {
          height: 5px;

          .progress::after {
            opacity: 1;
          }
        }
      }

      .time-display {
        color: white;
        font-size: 0.75rem;
        margin-top: 8px;
        font-family: Roboto, sans-serif;
        opacity: 0.9;
        font-weight: 500;
        letter-spacing: 0.5px;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
      }
    }

    &:hover {
      .video-controls {
        opacity: 1;
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }
}
</style>

