<template>
  <div class="section hero">
    <div class="video-content">
      <button class="play-button">
        <v-icon>
          <img alt="" src="../../../assets/images/play-black-outline-16x16.svg" width="16" height="16">
        </v-icon>

        <span>{{ heroContent.playButtonText }}</span>
      </button>
    </div>

    <div class="text-content">
      <h1 class="hero-header">{{ heroContent.title }}</h1>

      <div>
        <p class="paragraph">
          {{ heroContent.description }}
        </p>

        <div class="paragraph get-profile-button-wrapper">
          <a 
            class="get-profile-button"
            :href="heroContent.buyProfileUrl"
            @click="handleBuyClick"
          >
            <span>{{ heroContent.buyButtonText }}</span>

            <v-icon>
              <img alt="" src="../../../assets/images/external-link-icon-white-16x16.svg" width="16" height="16">
            </v-icon>
          </a>

          <a
            class="see-profile-button"
            :href="heroContent.sampleProfileUrl"
            @click="handleSampleClick"
          >
            {{ heroContent.sampleProfileText }}
          </a>
        </div>
      </div>

      <div class="social-media">
        <span>{{ heroContent.aboutUs }}</span>

        <div class="social-media-button-list">
          <a :href="icon.url" target="_blank" class="social-media-button" v-for="icon of socialMediaButtonList" :key="icon.name">
            <v-icon>
              <img :alt="icon.name" :src="icon.iconPath" width="16" height="16">
            </v-icon>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted, onUnmounted } from 'vue';
import tiktokIcon from '@/assets/images/social-media/tiktok-icon-black-16x16.svg';
import facebookIcon from '@/assets/images/social-media/facebook-icon-black-16x16.svg';
import youtubeIcon from '@/assets/images/social-media/youtube-icon-black-16x16.svg';
import instagramIcon from '@/assets/images/social-media/instagram-icon-black-16x16.svg';
import linkedinIcon from '@/assets/images/social-media/linkedin-icon-black-16x16.svg';

export default {
  name: 'HomePageHero',
  setup() {
    const videoPlayer = ref(null);

    const heroContent = reactive({
      title: 'Uwiecznij historię swoich bliskich.',
      description: 'Najpiękniejsze chwile i wspomnienia przekazywane dalszym pokoleniom.',
      buyProfileUrl: 'https://cart.easy.tools/checkout/memorial-profile/takzyli-profil-pamieci?ref=takzyli.pl&lang=pl',
      sampleProfileUrl: '/profil/55-02-03',
      buyButtonText: 'Zamów teraz',
      sampleProfileText: 'Zobacz przykładowy profil',
      aboutUs: 'O nas: ',
      playButtonText: 'Zobacz jak to działa',
    });

    const videoContent = reactive({
      shouldShowVideo: true,
      width: 580,
      height: 397,
      url: 'https://www.youtube.com/embed/Xw4WDc1Q6DY?si=nl7ab9PckZTg5DuK&controls=0&autoplay=1&mute=1&loop=1&list=PLhXRjF087HFjxb8QPnOBKEV2_wn5ovxSz',
      title: 'TakZyli.pl - platforma do tworzenia Profilu Pamięci',
      allowSettings: 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
    });

    const socialMediaButtonList = [
      {
        name: 'TikTok',
        url: 'https://www.tiktok.com/@takzyli.pl',
        iconPath: tiktokIcon,
      },
      {
        name: 'Facebook',
        url: 'https://www.facebook.com/profile.php?id=61570198959117',
        iconPath: facebookIcon,
      },
      {
        name: 'YouTube',
        url: 'https://www.youtube.com/@takzyli_pl',
        iconPath: youtubeIcon,
      },
      {
        name: 'Instagram',
        url: 'https://www.instagram.com/takzyli.pl',
        iconPath: instagramIcon,
      },
      {
        name: 'LinkedIn',
        url: 'https://www.linkedin.com/company/takżyli',
        iconPath: linkedinIcon,
      },
    ];

    const handleBuyClick = () => {
      console.log('Buy profile clicked');
    };

    const handleSampleClick = () => {
      console.log('Sample profile clicked');
    };

    const handleVideoLoad = () => {
      console.log('Video loaded');
    };

    const initializeVideo = () => {
      if (videoPlayer.value) {
        console.log('Video player initialized');
      }
    };

    // Lifecycle Hooks
    onMounted(() => {
      initializeVideo();
    });

    onUnmounted(() => {
      videoContent.shouldShowVideo = false;
    });

    // Expose to template
    return {
      // Refs
      videoPlayer,
      // Reactive state
      heroContent,
      videoContent,
      // Methods
      handleBuyClick,
      handleSampleClick,
      handleVideoLoad,
      socialMediaButtonList,
    };
  }
};
</script>

<style scoped lang="scss">
.hero {
  font-family: 'Source Serif 4', serif;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 16px 16px 48px;
  align-items: center;
  gap: 16px;

  @media (min-width: 768px) {
    padding: 56px 0 64px;
    flex-direction: row-reverse;
    align-items: flex-start;
    margin-bottom: 4px;
    gap: 0;
  }

  .hero-header {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2rem;

    @media (min-width: 768px) {
      font-weight: 600;
      font-size: 3.5rem;
      line-height: 5rem;
      margin-bottom: 12px;
    }
  }

  .text-content {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 16px;
    width: 100%;

    @media (min-width: 768px) {
      width: 612px;
      text-align: left;
      margin-bottom: 84px;
      gap: 0;
    }
  }

  .paragraph {
    font-size: 1.25rem;
    line-height: 2rem;
  }

  .get-profile-button-wrapper {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (min-width: 768px) {
      flex-direction: row;
      gap: 24px;
    }
  }

  .get-profile-button {
    padding: 14px 16px 14px;
    font-size: 0.875rem;
    line-height: 1.25rem;
    background-color: #2A4F3C;
    color: #FFFFFF;
    border-radius: 8px;
    text-decoration: none;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
  }

  .see-profile-button {
    color: #2A4F3C;
    font-weight: 600;
    font-family: Roboto, sans-serif;
    font-size: 0.875rem;
    cursor: pointer;
    line-height: 1.25rem;
    display: flex;
    align-items: center;
  }

  .social-media {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    border-left: 1px solid #A3A3A3;
    margin-top: 16px;
    padding-left: 12px;

    @media (min-width: 768px) {
      margin-top: 44px;
      justify-content: flex-start;
    }
  }

  .social-media-button-list {
    display: flex;
    gap: 12px;

    .social-media-button {
      width: 32px;
      height: 32px;
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(#0A0A0A, 0.1);
      border-radius: 4px;
      cursor: pointer;
    }
  }

  .video-content {
    width: 100%;
    aspect-ratio: 3 / 2;
    margin-top: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(../../../assets/images/hero-video-placeholder-280x186.png);
    background-position: center;
    background-size: contain;

    @media (min-width: 768px) {
      margin-top: 0;
      width: 560px;
      height: 400px;
      aspect-ratio: auto;
    }

    .play-button {
      height: 48px;
      top: 69px;
      left: 54px;
      padding: 14px;
      gap: 4px;
      border-radius: 8px;
      background: #FFFFFF;
      display: flex;
      align-items: center;
      font-weight: 500;
      font-family: Roboto, sans-serif;
      line-height: 20px;
    }
  }
}
</style>
