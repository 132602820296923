<template>
  <footer class="footer">
    <div class="footer-content-wrapper">
      <div class="footer-top">
        <a href="/" class="logo"></a>

        <div class="social-media">
          <div class="social-media-button-list">
            <a :href="icon.url" target="_blank" class="social-media-button" v-for="icon of socialMediaButtonList" :key="icon.name">
              <v-icon>
                <img :alt="icon.name" :src="icon.iconPath" width="16" height="16">
              </v-icon>
            </a>
          </div>
        </div>
      </div>

      <div class="footer-content">
        <div class="left-column">
          <p class="slogan">{{ footerTexts.slogan }}</p>

          <div class="column-section">
            <p class="name">{{ footerTexts.customerService.label }}</p>

            <p class="description"><a class="link" href="mailto:{{footerTexts.customerService.email}}">{{ footerTexts.customerService.email }}</a></p>
          </div>
        </div>

        <div class="footer-links">
          <a class="link" href="/regulamin">{{ footerTexts.termsOfService }}</a>
          <a class="link" href="/polityka-prywatnosci">{{ footerTexts.privacyPolicy }}</a>
          <span class="divider"></span>
          <a class="link" :href="footerTexts.buyProfileUrl">{{ footerTexts.buyProfile }}</a>
          <a class="link" href="/memorial-profile-sample">{{ footerTexts.sampleProfile }}</a>
          <a class="link" href="/o-nas">{{ footerTexts.aboutUs }}</a>
          <a class="link" href="/kontakt">{{ footerTexts.contact }}</a>
        </div>
      </div>

      <div class="google-privacy">
        <span>Ta strona jest chroniona przez reCAPTCHA i obowiązują </span>
        <a class="link" href="https://policies.google.com/privacy">Polityka prywatności</a> oraz
        <a class="link" href="https://policies.google.com/terms">Warunki korzystania z usługi</a> Google.
      </div>
    </div>
  </footer>
</template>

<script>
import tiktokIcon from "@/assets/images/social-media/tiktok-icon-black-16x16.svg";
import facebookIcon from "@/assets/images/social-media/facebook-icon-black-16x16.svg";
import youtubeIcon from "@/assets/images/social-media/youtube-icon-black-16x16.svg";
import instagramIcon from "@/assets/images/social-media/instagram-icon-black-16x16.svg";
import linkedinIcon from "@/assets/images/social-media/linkedin-icon-black-16x16.svg";

export default {
  name: 'Footer',
  setup() {
    const currentYear = new Date().getFullYear();

    const socialMediaButtonList = [
      {
        name: 'TikTok',
        url: 'https://www.tiktok.com/@takzyli.pl',
        iconPath: tiktokIcon,
      },
      {
        name: 'Facebook',
        url: 'https://www.facebook.com/profile.php?id=61570198959117',
        iconPath: facebookIcon,
      },
      {
        name: 'YouTube',
        url: 'https://www.youtube.com/@takzyli_pl',
        iconPath: youtubeIcon,
      },
      {
        name: 'Instagram',
        url: 'https://www.instagram.com/takzyli.pl',
        iconPath: instagramIcon,
      },
      {
        name: 'LinkedIn',
        url: 'https://www.linkedin.com/company/takżyli',
        iconPath: linkedinIcon,
      },
    ];
    const footerTexts = {
      termsOfService: 'Regulamin',
      privacyPolicy: 'Polityka prywatności',
      aboutUs: 'O nas',
      contact: 'Kontakt',
      slogan: 'Zachowaj wspomnienia, przekaż historię.',
      buyProfile: 'Kup Profil Pamięci',
      buyProfileUrl: 'https://cart.easy.tools/checkout/memorial-profile/takzyli-profil-pamieci?ref=takzyli.pl',
      sampleProfile: 'Przykładowy profil',
      customerService: {
        label: 'Obsługa klienta:',
        email: 'biuro@takzyli.pl'
      }
    }

    return {
      currentYear,
      socialMediaButtonList,
      footerTexts,
    }
  },
}
</script>

<style scoped lang="scss">
.footer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #000000;
  font-size: 0.875rem;
  padding: 24px 16px;

  @media (min-width: 768px) {
    padding: 64px 0;
  }

  .footer-top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(#E5E5E5, 0.3);

    .logo {
      height: 36px;
      width: 79px;
      background-image: url('../../assets/images/logo-white.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .social-media {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .social-media-button-list {
    display: flex;
    gap: 8px;

    @media (min-width: 768px) {
      gap: 12px;
    }

    .social-media-button {
      width: 24px;
      height: 24px;
      padding: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #FFFFFF;
      border-radius: 4px;
      cursor: pointer;

      @media (min-width: 768px) {
        width: 32px;
        height: 32px;
        padding: 8px;
      }
    }
  }

  .google-privacy {
    width: 100%;
    color: #6D6D6D;
    font-size: 0.75rem;
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid rgba(#FFFFFF, 0.3);

    @media (min-width: 768px) {
      margin-top: 24px;
      padding-top: 24px;
    }
  }

  .footer-content-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
    gap: 16px;
    color: #dedede;

    @media (min-width: 768px) {
      max-width: 1296px;
      padding: 0 16px;
      height: 80px;
      justify-content: space-between;
    }
  }

  .footer-content {
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
    }

    .left-column {
      display: flex;
      flex-direction: column;
      gap: 8px;

      @media (min-width: 768px) {
        gap: 16px;
      }
    }

    .slogan {
      font-family: 'Source Serif 4', serif;
      font-size: 1.25rem;
      line-height: 2rem;
      font-weight: 600;

      @media (min-width: 768px) {
        font-size: 2rem;
        line-height: 2.75rem;
        width: 492px;
      }
    }
  }

  .column-section {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .name {
      font-family: Roboto, sans-serif;
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 400;
      color: rgba(#FFFFFF, 0.5);
    }

    .description {
      font-family: 'Source Serif 4', serif;
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 400;

      @media (min-width: 768px) {
        font-size: 1.25rem;
        line-height: 2rem;
      }
    }
  }

  .footer-links {
    width: fit-content;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 4px;

    @media (min-width: 420px) {
      gap: 8px;
      align-items: flex-end;
    }

    .divider {
      width: 100%;
      height: 1px;
      margin: 4px 0;
      background-color: rgba(#FFFFFF, 0.3);
    }
  }

  .link {
    color: #FFFFFF;
    text-decoration: none;
    border-bottom: 1px solid transparent;
    transition: color 0.3s ease, border 0.3s ease;

    &:hover {
      border-bottom: 1px solid #A28546;
    }
  }
}

</style>
