<template>
  <div class="home-page">
    <HomePageHero />

    <AboutOurPlatform />

    <MemorialProfileSearch />

    <HowItWorks />

    <Testimonials />

    <WhatServiceContains />

<!--    <SubscribeNewsletter />-->

<!--    <OurPartnersAdditionalServices />-->

    <Faq />
  </div>
</template>

<script>
import HomePageHero from './components/HomePageHero.vue';
import AboutOurPlatform from './components/AboutOurPlatform.vue';
import MemorialProfileSearch from './components/MemorialProfileSearch.vue';
import HowItWorks from './components/HowItWorks.vue';
import Testimonials from './components/Testimonials.vue';
import WhatServiceContains from './components/WhatServiceContains.vue';
import Faq from './components/Faq.vue';

window.onload = function() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};

export default {
  name: 'HomePage',
  components: {
    HomePageHero,
    AboutOurPlatform,
    MemorialProfileSearch,
    HowItWorks,
    Testimonials,
    WhatServiceContains,
    Faq,
  }
}
</script>

<style lang="scss" scoped>
.home-page {
  min-height: 100vh;
  width: 100%;
  background-image: url('@/assets/images/homepage-background-image-mobile.png');
  background-position: right top -88px;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;

  @media (min-width: 768px) {
    background-image: url('@/assets/images/homepage-background-image.png');
    background-size: 72%;
  }
}
</style>
