<template>
  <section class="testimonials">
    <div class="corner-image top-left"></div>
    <div class="corner-image top-right"></div>
    <div class="corner-image bottom-left"></div>
    <div class="corner-image bottom-right"></div>

    <div class="testimonials-content-wrapper">
      <div class="section-content">
        <div class="testimonial">
          <div class="quote-mark"></div>

          <p class="message" v-html="sectionTexts.testimonial.message"></p>

          <span class="divider"></span>

          <div class="author">
            <img 
              :src="sectionTexts.testimonial.author.avatarPath" 
              :alt="sectionTexts.testimonial.author.name"
              class="author-avatar"
            >
            <div class="author-info">
              <div class="author-name">{{ sectionTexts.testimonial.author.name }}</div>
              <div class="author-position">{{ sectionTexts.testimonial.author.position }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import xavAvatar from '@/assets/images/testimonials-avatar-xav.png';
import quoteMarkIcon from '@/assets/images/quote-mark-grey.svg';

export default {
  name: 'Testimoanials',
  setup() {
    const sectionTexts = {
      testimonial: {
        message: 'Wyobraź sobie, że trzymasz w dłoniach księgę o swoim bliskim, wypełnioną po brzegi najpiękniejszymi wspomnieniami.<br>Z każdą  stroną odkrywasz na nowo historie, które ukształtowały Waszą rodzinę.',
        author: {
          avatarPath: xavAvatar,
          name: 'Ksawery Zieliński',
          position: 'Założyciel',
        }

      }
    };

    return {
      sectionTexts,
      quoteMarkIcon,
    };
  }
};
</script>

<style scoped lang="scss">
.testimonials {
  width: 100vw;
  height: auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 32px 0;

  @media (min-width: 768px) {
    height: 584px;
    margin: 24px 0 160px;
  }

  .divider {
    background-color: #A3A3A3;
    height: 2px;
    width: 48px;
    margin: 16px 0;

    @media (min-width: 768px) {
      width: 48px;
      margin: 24px 0;
    }
  }

  .corner-image {
    display: none;

    @media (min-width: 768px) {
      display: block;
      position: absolute;
      z-index: 1;
      width: 220px;
      height: 280px;
      background-repeat: no-repeat;
      background-size: contain;
    }

    &.top-left {
      top: 0;
      left: 0;
      transform: translate(0%, -86px);
      background-image: url('../../../assets/images/testimonials-bg-top-left.png');
      background-size: auto;
    }

    &.top-right {
      top: 0;
      right: 0;
      transform: translate(20px, -84px);
      background-image: url('../../../assets/images/testimonials-bg-top-right.png');
      background-size: auto;
    }

    &.bottom-left {
      bottom: 0;
      left: 0;
      transform: translate(0%, 136px);
      background-image: url('../../../assets/images/testimonials-bg-bottom-left.png');
      background-size: auto;
    }

    &.bottom-right {
      bottom: 0;
      right: 0;
      transform: translate(0%, 160px);
      background-image: url('../../../assets/images/testimonials-bg-bottom-right.png');
      background-size: auto;
    }
  }

  .testimonials-content-wrapper {
    width: 100%;
    background-color: #171717;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: visible;
    padding: 24px 16px;

    @media (min-width: 768px) {
      width: 100%;
      min-height: 584px;
      padding: 84px 0 94px;
    }
  }

  .section-content {
    max-width: 100%;

    @media (min-width: 768px) {
      max-width: 1296px;
    }
  }

  .testimonial {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    position: relative;
    max-width: 720px;
    margin: 0 auto;

    .quote-mark {
      width: 19.61px;
      height: 16px;
      margin-bottom: 16px;
      background-image: url('@/assets/images/quote-mark-grey.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;

      @media (min-width: 768px) {
        margin-bottom: 32px;
        width: 31.86px;
        height: 26px;
      }
    }

    .message {
      font-family: 'Source Serif 4', serif;
      font-size: 0.875rem;
      line-height: 1.5rem;
      font-weight: 600;
      position: relative;
      z-index: 1;
      letter-spacing: 0.5px;

      @media (min-width: 768px) {
        width: 100%;
        font-size: 1.5rem;
        line-height: 2rem;
        max-width: 720px;
        letter-spacing: 0.1px;
      }
    }

    .author {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 16px;

      .author-avatar {
        width: 48px;
        height: 48px;
        border-radius: 50%;
      }

      .author-info {
        .author-name {
          font-family: 'Source Serif 4', serif;
          font-size: 1rem;
          line-height: 1.5rem;
          font-weight: 600;
        }

        .author-position {
          font-family: 'Source Serif 4', serif;
          font-size: 0.875rem;
          line-height: 1.5rem;
          font-weight: 400;
          color: #FFFFFF;
        }
      }
    }
  }
}
</style>
