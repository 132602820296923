<template>
  <div class="static-page">
    <div class="content">
      <h1 class="page-header">Polityka Prywatności</h1>

      <article id="cel-polityki" class="static-page-content-paragraph">
        <h3 class="static-page-content-paragraph-header">Cel Polityki Prywatności</h3>
        <p class="static-page-content-paragraph">
          Celem niniejszej Polityki Prywatności jest poinformowanie Klientów i Użytkowników o zasadach przetwarzania danych osobowych przez portal Takzyli.pl, prowadzony przez firmę Progrise Szymon Konieczny z siedzibą przy ul. Marcina Radockiego 156/1, 40-645 Katowice, Polska, NIP: 6342611135.
        </p>
      </article>

      <article id="ochrona-danych" class="static-page-content-paragraph">
        <h3 class="static-page-content-paragraph-header">Ochrona danych</h3>
        <p class="static-page-content-paragraph">
          Firma Progrise Szymon Konieczny jako administrator portalu przykłada dużą wagę do bezpieczeństwa danych osobowych Klientów oraz Użytkowników platformy Takzyli.pl. Pomimo stosowania zaawansowanych metod ochrony danych, nie można zagwarantować ich całkowitego bezpieczeństwa w Internecie. Wymagamy podania danych osobowych wyłącznie wtedy, gdy jest to niezbędne do świadczenia naszych usług, na przykład podczas rejestracji konta, gdzie przetwarzamy adres e-mail.
        </p>
      </article>

      <article id="zgoda-na-przetwarzanie-danych" class="static-page-content-paragraph">
        <h3 class="static-page-content-paragraph-header">Zgoda na przetwarzanie danych</h3>
        <p class="static-page-content-paragraph">
          Korzystając z usług Takzyli.pl, Użytkownik wyraża zgodę na gromadzenie i wykorzystywanie danych zgodnie z niniejszą Polityką Prywatności. Przekazanie danych osobowych podczas rejestracji oznacza zgodę na ich przetwarzanie w celu realizacji usług zgodnie z Regulaminem. Użytkownik zgadza się również na otrzymywanie ważnych informacji dotyczących usług na podany adres e-mail.
        </p>
      </article>

      <article id="gromadzenie-danych" class="static-page-content-paragraph">
        <h3 class="static-page-content-paragraph-header">Gromadzenie danych</h3>
        <p class="static-page-content-paragraph">
          Aby zapewnić wysoką jakość usług, podczas korzystania z platformy Takzyli.pl mogą być zbierane pewne informacje dotyczące Użytkowników. Platforma korzysta z usług zewnętrznych dostawców technologicznych, którzy mogą zbierać dane umożliwiające identyfikację Użytkownika. Zewnętrzne firmy zobowiązują się do zachowania poufności danych i ich wykorzystywania wyłącznie w celu świadczenia usług na rzecz Takzyli.pl.
        </p>
      </article>

      <article id="pliki-cookie" class="static-page-content-paragraph">
        <h3 class="static-page-content-paragraph-header">Pliki cookie</h3>
        <p class="static-page-content-paragraph">
          Platforma Takzyli.pl może korzystać z plików cookie do zbierania informacji, co pozwala na ulepszanie naszych usług. Pliki cookie są małymi plikami danych przechowywanymi w urządzeniu Użytkownika, które mogą być zaakceptowane lub odrzucone. Odmowa akceptacji plików cookie może ograniczyć dostęp do niektórych funkcji serwisu.
        </p>
      </article>

      <article id="udostepnianie-danych" class="static-page-content-paragraph">
        <h3 class="static-page-content-paragraph-header">Udostępnianie danych</h3>
        <p class="static-page-content-paragraph">
          Dane osobowe Klientów nie są przekazywane ani sprzedawane stronom trzecim, z wyjątkiem sytuacji, gdy wymaga tego prawo. Platforma Takzyli.pl może zawierać odnośniki do zewnętrznych stron, które posiadają własne Polityki Prywatności. Firma Progrise Szymon Konieczny nie ponosi odpowiedzialności za polityki prywatności tych stron.
        </p>
      </article>

      <article id="prawa-uzytkownika" class="static-page-content-paragraph">
        <h3 class="static-page-content-paragraph-header">Prawa Użytkownika</h3>
        <p class="static-page-content-paragraph">
          Użytkownik ma prawo:
        </p>
        <ul class="static-page-content-unordered-list">
          <li>Uzyskać dostęp do swoich danych i otrzymać ich kopię.</li>
          <li>Sprostować lub uzupełnić swoje dane.</li>
          <li>Sprzeciwić się przetwarzaniu danych i zażądać zaprzestania ich przetwarzania.</li>
          <li>Żądać usunięcia swoich danych.</li>
        </ul>
        <p class="static-page-content-paragraph">
          Aby skorzystać ze swoich praw, prosimy o kontakt poprzez adres e-mail kontaktowy dostępny na naszej stronie.
        </p>
      </article>

      <article id="kontakt-i-aktualizacje" class="static-page-content-paragraph">
        <h3 class="static-page-content-paragraph-header">Kontakt i aktualizacje</h3>
        <p class="static-page-content-paragraph">
          W razie pytań lub sugestii dotyczących Polityki Prywatności, prosimy o kontakt za pomocą poczty e-mail. Niniejsza Polityka Prywatności może być aktualizowana, dlatego zachęcamy do regularnego przeglądania tej strony.
        </p>
        <p class="static-page-content-paragraph">
          Aktualizacja: 8 stycznia 2025 r.
        </p>
      </article>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
};
</script>

<style lang="scss" scoped>
.static-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 16px 0;

  @media (min-width: 768px) {
    padding: 24px 24px 0;
    height: 100%;
  }

  .content {
    max-width: 960px;
  }

  .page-header {
    font-size: 1rem;
    text-align: center;
    margin-bottom: 16px;

    @media (min-width: 768px) {
      font-size: 2rem;
      text-align: left;
      margin-bottom: 32px;
    }
  }

  .static-page-content-paragraph-header {
    font-family: Roboto, sans-serif;
    margin-bottom: 24px;

    @media (min-width: 768px) {
      margin-bottom: 32px;
    }
  }

  .static-page-content-subparagraphs,
  .static-page-content-ordered-list-paragraph,
  .static-page-content-paragraph {
    line-height: 150%;
    letter-spacing: 0.5px;
    font-size: 0.875rem;
    margin: 16px 0;
    text-align: justify;
    font-family: Roboto, sans-serif;

    @media (min-width: 768px) {
      font-size: 1.25rem;
      letter-spacing: 0.1px;
    }
  }

  .static-page-content-paragraph {
    @media (min-width: 768px) {
      padding: 0 24px;
    }
  }

  .static-page-content-unordered-list,
  .static-page-content-subparagraphs {
    margin-left: 16px;
  }

  .static-page-content-ordered-list-paragraph,
  .static-page-content-subparagraphs {
    @media (min-width: 768px) {
      margin-left: 24px;
    }
  }
}
</style>